import { makeStyles } from '@material-ui/core';
import { tourService } from 'client_shared/src/services';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { tourModule } from 'shared/src/modules/tour';
import PrimaryBuyingButton from '../../buttons/PrimaryBuyingButton';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '-16px',
        marginLeft: '-16px',
        '& > *': {
            marginTop: '16px',
            marginLeft: '16px',
        },
    },
    title: {
        marginBottom: '24px',
    },
    card: {
        border: '2px solid #D9D9D9',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        padding: '32px 24px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'calc(100% / 2 - 16px)',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% / 2 - 16px)',
        },
        [theme.breakpoints.up('lg')]: {
            width: 'calc(100% / 2 - 16px)',
        },
        '& > *': {
            '&:not(:first-child)': {
                marginTop: '24px',
            }
        }
    },
    descriptionBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    description: {
        '& > span': {
            fontWeight: 'bold',
        }
    },
    message: {
        '&.paragraph4': {

            color: '#5C5C66'
        }
    },
    tourDateBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    tourDate: {
        fontWeight: 'bold'
    },
    tourDateAsDayOfWeek: {
    },
    link: {
        marginTop: '24px',
        '&.linkSecondary': {
            fontSize: '12px !important',
        },
        cursor: 'pointer',
    }
}));

function TourTimeCard(props) {
    const { tour, tourDate, tourTime } = props;
    const [tourAmount, setTourAmount] = useState(null);
    const [tourLimit, setTourLimit] = useState(null);
    const classes = useStyles();

    const { t } = useTranslation();

    const isPermanent = tourDate.isPermanent;
    const fromDate = tourDate.fromDate;
    const toDate = tourDate.toDate || fromDate;
    const tourDateInterval = {fromDate: moment(fromDate), toDate: moment(toDate)};

    useEffect(() => {
        async function getTourAmount() {
            if (!tourDate.fromDate || !tourTime.time) {
                return;
            }

            const { tourAmount, tourLimit } = await tourService.getTourAmountForTourTime(tour.id, tourDate.fromDate, tourTime.time, tourDate.id, tourTime.id);

            setTourLimit(tourLimit);
            setTourAmount(tourAmount);
        }

        if (tour && tourDate && tourTime) {
            getTourAmount().catch(error => console.error(error));
        }
    }, [tour, tourDate, tourTime]);

    const minimumLimitReached = tourTime.minimumLimit === null || tourTime.minimumLimit === 0 || tourAmount >= tourTime.minimumLimit;

    return (
        <div className={classes.card}>
            {!isPermanent &&
                <div className={clsx('paragraph2', classes.tourDateBlock)}>
                    <div className={classes.tourDate}>
                        {tourModule.formatTourDateInterval(tourDateInterval)}
                    </div>
                    <div className={classes.tourDateAsDayOfWeek}>
                        {tourModule.formatTourDateIntervalAsDayOfWeek(tourDateInterval)}
                    </div>
                </div>
            }
            <h3>{moment(tourTime.time, 'HH:mm:ss').format('HH:mm')}</h3>
            <div className={classes.descriptionBlock}>
                <div className={clsx('paragraph4', classes.message)}>
                    {tourTime.message}
                </div>
            </div>
            <div className={classes.descriptionBlock}>
                <div className={clsx('paragraph3', classes.description)}>
                    {tourAmount !== null && tourLimit !== null && tourLimit > 0 && <><span>{t('Text.Fullness')}:</span> {tourAmount} / {tourLimit}</>}
                    {tourAmount !== null && tourLimit !== null && tourLimit === 0 && <><span>{t('Text.Fullness')}:</span> {tourAmount}</>}
                </div>
{/*
                <div className={clsx('paragraph3', classes.description)}>
                    <span>1200 Ft / {t('Text.FromPerson')}</span>
                </div>
*/}
            </div>
            {tourModule.hasTickets(tour) &&
            <PrimaryBuyingButton 
                variant={'normal'}  
                tour={tour} 
                date={tourDate.fromDate} 
                time={tourTime.time} 
                tourDateId={tourDate.id} 
                tourTimeId={tourTime.id}
                green={minimumLimitReached}
            />
            }
        </div>
    );
}


export default function TourTimeTable(props) {
    const { tour } = props;
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const { t } = useTranslation();

    const location = useLocation();

    useEffect(() => {
        if (URLSearchParams && location.search) {
            let searchParams = new URLSearchParams(location.search);
            const dateParam = searchParams.get('date');
            const timeParam = searchParams.get('time');
            
            let selectedDate = null;
            let selectedTime = null;

            if (dateParam && dateParam.length === 8) {
                selectedDate = moment(dateParam, 'YYYYMMDD').toDate();
            }

            if (timeParam && timeParam.length === 4) {
                selectedTime = moment(timeParam, 'HHmm').format('HH:mm:ss');
            }

            setSelectedDate(selectedDate);
            setSelectedTime(selectedTime);
        }
    }, [location]);


    if (!tour) {
        return null;
    }

    const tourDatesAndTimes = (tour && tourModule.getTourDatesAndTimesFromDate(tour, moment())) || [];

    if (!tourDatesAndTimes || !Array.isArray(tourDatesAndTimes) || tourDatesAndTimes.length === 0) {
        return null;
    }

    const filteredTourDatesAndTimes = tourDatesAndTimes.filter(({tourDate, tourTime}) => {
        const datesAreEqual = moment(tourDate.fromDate).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD');
        const timesAreEqual = tourTime.time === selectedTime;
        return ((!selectedDate && tourTime.public) || datesAreEqual) && ((!selectedTime && tourTime.public) || timesAreEqual);
    });

    function handleMoreTimesClick() {
        setSelectedDate(null);
        setSelectedTime(null);
    }

    return (
        <div>
            <h3 className={classes.title}>{t('Text.TourTimes')}</h3>
            <div className={classes.layout}>
                {filteredTourDatesAndTimes.map(({tourDate, tourTime}) =>
                    <TourTimeCard key={tourTime.id} tourDate={tourDate} tourTime={tourTime} tour={tour}/>
                )}
            </div>
        </div>
    );
}
